import dayjs from 'dayjs';
import { DateTimeISO8601 } from '@/domains/common';

export interface ITimeBetweenAttempts {
  days: number;
  hours: number;
  minutes: number;
}

function getTimeBetweenAttempts(nextAttemptAt: DateTimeISO8601): ITimeBetweenAttempts {
  const now = dayjs();
  const next = dayjs(nextAttemptAt ?? 0);
  const diff = next.diff(now) ?? 0;
  const duration = dayjs.duration(diff);

  return {
    days: duration.days(),
    hours: duration.hours(),
    minutes: duration.minutes(),
  };
}

function getRunTestAgainButtonTimePart(timeBetweenAttempts: ITimeBetweenAttempts): string {
  const { days, hours, minutes } = timeBetweenAttempts;

  const resultArray = [];

  if (days > 0) {
    resultArray.push(`${days} д`);
  }

  if (hours > 0) {
    resultArray.push(`${hours} ч`);
  }

  if (minutes > 0) {
    resultArray.push(`${minutes} мин`);
  }

  return resultArray.join(' ');
}

function hasNextAttemptsTime(timeBetweenAttempts: ITimeBetweenAttempts): boolean {
  return timeBetweenAttempts.days > 0 || timeBetweenAttempts.hours > 0 || timeBetweenAttempts.minutes > 0;
}

export {
  getTimeBetweenAttempts,
  getRunTestAgainButtonTimePart,
  hasNextAttemptsTime,
};
