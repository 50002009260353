
import {
  computed, defineComponent, PropType, ref, Ref,
} from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { useI18n } from '@/plugins/vue-i18n';
import { useDi } from '@/plugins/di';

import { IBreadcrumb, PlayerSessionStatus, UUID } from '@/domains/common';
import { IAtom, AtomType } from '@/domains/atom';
import {
  IQuiz, QuizAnswersDisplayType, toQuizResultView, toQuizView,
} from '@/domains/quiz';
import { IButtonsState } from '@/domains/assignment';

import {
  getTimeBetweenAttempts,
  getRunTestAgainButtonTimePart,
  hasNextAttemptsTime,
  ITimeBetweenAttempts,
} from '@/helpers/time/blockingRunQuizButton';

import Names from '@/plugins/vue-router/routeNames';

import StepsButtons from '@/domains/assignment/components/StepsButtons/StepsButtons.vue';
import EmptyBlock from '@/components/ui/EmptyBlock.vue';
import TestResults from '@/components/ui/TestResults';
import TestDynamicResult from '@/components/ui/TestResults/TestDynamicResult.vue';
import AtomHasNewVersionBanner from '@/components/catalogue/AtomHasNewVersionBanner/AtomHasNewVersionBanner.vue';

import * as LxpQuizTestService from '@/services/api/lxp-quiz-test';
import { useQuizResult } from '@/domains/quiz/composables/useQuizResult';

import QuizResultSuccessImage from '@/assets/img/quiz-result-success.png';
import QuizResultAbsoluteSuccessImage from '@/assets/img/quiz-result-absolute-success.png';
import QuizResultFailImage from '@/assets/img/quiz-result-fail.png';

export default defineComponent({
  name: 'QuizResultView',

  components: {
    EmptyBlock,
    StepsButtons,
    TestResults,
    TestDynamicResult,
    AtomHasNewVersionBanner,
  },

  props: {
    track: {
      type: Object as PropType<IAtom>,
      required: true,
    },

    buttonsState: {
      type: Object as PropType<IButtonsState>,
      default: () => ({ prev: { enabled: false } }),
    },
  },

  setup(props, { emit }) {
    const { t, tc } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const di = useDi();

    const isLoading = ref<boolean>(true);
    const isError = ref<boolean>(false);
    const quiz = ref<IQuiz>({
      playerSessionStatus: PlayerSessionStatus.NEW,
      name: '',
      description: '',
      questionsCount: 0,
      testTimeout: 0,
      freeFlow: false,
      results: {
        passed: false, // признак успешного прохождения теста
        correctAnswers: 0, // количество правильных ответов
        testThreshold: 0, // порог прохождения в процентах от 0 до 1
        answersDisplayType: QuizAnswersDisplayType.ALL, // режим отображения результатов
        percent: 0,
        percentDifference: 0, // диапазон от -1 до 1, т.к. может быть отрицательная динамика
      },
      maxAttempts: 0,
      attemptsCount: 0,
      nextAttemptAt: '',
    });
    const hasNewVersionValue = ref<boolean>(false);
    const atomType = ref<AtomType>(AtomType.QUIZ);

    const breadcrumbs: Ref<IBreadcrumb[]> = computed(() => [
      {
        text: t('LearningTracks.title'),
        to: {
          name: Names.R_APP_LEARNING_TRACKS,
        },
        'data-test-label': 'my-learning',
      },
      {
        text: props.track.name ?? '',
        to: {
          name: Names.R_APP_LEARNING_TRACK,
        },
        'data-test-label': 'track-name',
      },
      {
        text: quiz.value.name,
        disabled: true,
        'data-test-label': 'quiz-name',
      },
    ]);

    const playerSessionId: Ref<number> = computed(() => Number(route.params.playerSessionId));

    const {
      testPassed,
      currentPercent,
      testPassingStatusThreshold,
      fetchResults,
      results,
      hasResults,
      showAnswers,
      answersButtonName,
    } = useQuizResult({ quiz, playerSessionId });

    const testPassingStatusImageSrc: Ref<string> = computed(() => {
      if (testPassed.value && currentPercent.value === 100) {
        return QuizResultAbsoluteSuccessImage;
      }

      if (testPassed.value && currentPercent.value < 100) {
        return QuizResultSuccessImage;
      }

      return QuizResultFailImage;
    });

    const testPassingStatusTitle: Ref<string> = computed(() => {
      if (testPassed.value && currentPercent.value === 100) {
        return t('QuizResultView.testPassingStatusTitleAbsoluteSuccess');
      }

      if (testPassed.value && currentPercent.value < 100) {
        return t('QuizResultView.testPassingStatusTitleSuccess');
      }

      if (!testPassed.value && quiz.value.attemptsCount === quiz.value.maxAttempts) {
        return t('QuizResultView.testPassingStatusTitleErrorWithoutAttempts');
      }

      return t('QuizResultView.testPassingStatusTitleError');
    });

    const testPassingStatusDescription: Ref<string> = computed(() => t('QuizResultView.testPassingStatusDescription', {
      passed: quiz.value.results?.correctAnswers,
      all: tc('pluralized.questionsParent', quiz.value.questionsCount),
      currentPercent: currentPercent.value,
    }));
    const stepId: Ref<UUID> = computed(() => route.params.stepId);
    const currentResultDifference: Ref<number> = computed(() => quiz.value?.results!.percentDifference);
    const isMoreThanOneResult: Ref<boolean> = computed(() => results.value?.length > 1);
    const showPassAgainButton: Ref<boolean> = computed(() => {
      return quiz.value.results!.percent < 1 && (quiz.value.attemptsCount < quiz.value.maxAttempts
        || quiz.value.maxAttempts === 0);
    });
    const timeBetweenAttempts: Ref<ITimeBetweenAttempts> = computed(() =>
      getTimeBetweenAttempts(quiz.value?.nextAttemptAt ?? ''));
    const hasNextAttemptsTimeFlag: Ref<boolean> = computed(() => hasNextAttemptsTime(timeBetweenAttempts.value));
    const runTestAgainButtonText: Ref<string> = computed(() => {
      const str = getRunTestAgainButtonTimePart(timeBetweenAttempts.value);

      return str.length
        ? t('QuizResultView.runTestAgainButtonText.time', { time: str })
        : t('QuizResultView.runTestAgainButtonText.withoutTime');
    });

    const onClickShowAnswersHandler = () => {
      router.push(toQuizResultView({ stepId: stepId.value }));
    };

    const fetchQuiz = async () => {
      const params = {
        playerSessionId: playerSessionId.value,
      };

      try {
        quiz.value = await LxpQuizTestService.testInfoGet(params);
      } catch (e: any) {
        di.notify.error({ content: e.message });
      }
    };

    const nextStep = () => {
      emit('step:next');
      emit('next-step');
    };

    const previousStep = () => {
      emit('step:prev');
      emit('prev-step');
    };

    const goToQuizView = async () => {
      await router.push(toQuizView({
        playerSessionId: playerSessionId.value,
        stepId: stepId.value,
      }));
    };

    const checkNewVersion = async () => {
      try {
        const { hasNewVersion = false } = await LxpQuizTestService.testSessionCheckVersion({
          playerSessionId: playerSessionId.value,
        });

        hasNewVersionValue.value = hasNewVersion;
      } catch (e) {
        console.error(e);
      }
    };

    const newVersionLoaded = () => {
      emit('new-version:loaded');
    };

    const init = async () => {
      try {
        isLoading.value = true;
        await Promise.all([
          fetchQuiz(),
          fetchResults(),
          checkNewVersion(),
        ]);
        isLoading.value = false;
      } catch (e) {
        console.error(e);
        isError.value = true;
      }
    };
    init();

    return {
      isLoading,
      isError,
      quiz,
      breadcrumbs,
      showAnswers,
      testPassingStatusImageSrc,
      testPassingStatusTitle,
      testPassingStatusDescription,
      testPassingStatusThreshold,
      results,
      currentResultDifference,
      isMoreThanOneResult,
      hasResults,
      showPassAgainButton,
      timeBetweenAttempts,
      runTestAgainButtonText,
      hasNextAttemptsTimeFlag,
      answersButtonName,
      hasNewVersionValue,
      atomType,
      onClickShowAnswersHandler,
      nextStep,
      previousStep,
      newVersionLoaded,
      goToQuizView,
    };
  },
});
